<template>
	<div class="container">
		<a-form :form="formCompany">
			<a-row :gutter="16">
				<a-col :span="18">
					<a-form-item
						label="CNPJ"
						:has-feedback="hasFeedback"
						:help="searchHelp"
						:validate-status="searchStatus"
					>
						<a-input
							v-decorator="[
								'cnpj',
								{
									initialValue: company.cnpj,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe o cnpj do seu restaurante!',
										},
										{
											validator: onValidateCNPJ,
										},
									],
								},
							]"
							v-mask="'##.###.###/####-##'"
							placeholder="Informe o CNPJ do seu restaurante"
							type="tel"
							:auto-focus="true"
							@change="validatedCNPJ = false"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="6">
					<a-form-item label="Tipo" has-feedback>
						<a-select
							v-decorator="[
								'type',
								{
									initialValue: company.type,
									rules: [
										{
											required: true,
											message:
												'Por favor, selecione um tipo para seu restaurante!',
										},
									],
								},
							]"
							placeholder="Selecione um tipo"
							class="select"
							:disabled="desabledFieldsCompany"
						>
							<a-select-option value="MATRIZ">
								Matriz
							</a-select-option>
							<a-select-option value="FILIAL">
								Filial
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row>
				<a-col>
					<a-form-item label="Nome" has-feedback>
						<a-input
							v-decorator="[
								'company_name',
								{
									initialValue: company.company_name,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe um nome!',
										},
									],
								},
							]"
							placeholder="Informe o nome do seu restaurante"
							:disabled="desabledFieldsCompany"
						/>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="14">
					<a-form-item label="Fantasia">
						<a-input
							v-decorator="[
								'fantasy_name',
								{
									initialValue: company.fantasy_name,
								},
							]"
							placeholder="Informe o nome de fantasia do seu restaurante"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="10">
					<a-form-item label="Telefone" has-feedback>
						<a-input
							v-decorator="[
								'phone',
								{
									initialValue: company.phone,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe o telefone do seu restaurante!',
										},
									],
								},
							]"
							v-mask="['(##) ####-####', '(##) #####-####']"
							placeholder="Informe o número de telefone para o seu restaurante"
							type="tel"
						/>
					</a-form-item>
				</a-col>
			</a-row>
		</a-form>
		<a-form :form="formAddress">
			<a-row :gutter="16">
				<a-col :span="8">
					<a-form-item label="CEP" has-feedback>
						<a-input
							v-decorator="[
								'postal_code',
								{
									initialValue: address.postal_code,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe o CEP do seu restaurante!',
										},
										{
											pattern: /^\d{2}\d{3}-\d{3}$/,
											message:
												'Por favor, informe um CEP válido',
										},
									],
								},
							]"
							v-mask="['#####-###']"
							placeholder="Informe o CEP do seu restaurante"
							type="tel"
							@change="onChangeZip"
						/>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row type="flex" :gutter="16">
				<a-col :span="18">
					<a-form-item label="Logradouro" has-feedback>
						<a-input
							v-decorator="[
								'street_name',
								{
									initialValue: address.street_name,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe a rua do seu restaurante!',
										},
									],
								},
							]"
							placeholder="Informe a avenida/rua em que está localizado o seu restaurante"
							:disabled="desabledFieldsAddress"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="6">
					<a-form-item label="Número" has-feedback>
						<a-input-number
							v-decorator="[
								'street_number',
								{
									initialValue: address.street_number,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe o número do seu restaurante!',
										},
									],
								},
							]"
							style="width: 170px;"
							placeholder="1234"
							:min="0"
						/>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row>
				<a-col>
					<a-form-item label="Complemento">
						<a-input
							v-decorator="[
								'complement',
								{ initialValue: address.complement },
							]"
							placeholder="Informações complementares sobre o endereço"
						/>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row type="flex" :gutter="14">
				<a-col :span="10">
					<a-form-item label="Bairro" has-feedback>
						<a-input
							v-decorator="[
								'neighborhood',
								{
									initialValue: address.neighborhood,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe o bairro do seu restaurante!',
										},
									],
								},
							]"
							placeholder="Informe o bairro em que está localizado o seu restaurante"
							:disabled="desabledFieldsAddress"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="8">
					<a-form-item label="Cidade" has-feedback>
						<a-input
							v-decorator="[
								'city',
								{
									initialValue: address.city,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe a cidade do seu restaurante!',
										},
									],
								},
							]"
							placeholder="Informe a cidade em que está localizado o seu restaurante"
							:disabled="desabledFieldsAddress"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="3">
					<a-form-item label="Estado" has-feedback>
						<a-select
							v-decorator="[
								'state',
								{
									initialValue: address.state,
									rules: [
										{
											required: true,
											message:
												'Por favor, selecione um estado!',
										},
									],
								},
							]"
							placeholder="Selecione..."
							class="select"
							:disabled="desabledFieldsAddress"
						>
							<a-select-option
								v-for="state in stateList"
								:key="state"
								:value="state"
							>
								{{ state }}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
				<a-col :span="3">
					<a-form-item label="País" has-feedback>
						<a-select
							v-decorator="[
								'country',
								{
									initialValue: address.country,
									rules: [
										{
											required: true,
											message:
												'Por favor, selecione um país!',
										},
									],
								},
							]"
							placeholder="Selecione..."
							class="select"
							:disabled="desabledFieldsAddress"
						>
							<a-select-option value="BR">Brasil</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
			</a-row>
		</a-form>
		<a-form :form="formBank">
			<a-row :gutter="16">
				<a-col :span="14">
					<a-form-item label="Banco" has-feedback>
						<a-select
							v-decorator="[
								'bank',
								{
									initialValue: bank_account.bank,
									rules: [
										{
											required: true,
											message:
												'Por favor, selecione o seu banco!',
										},
									],
								},
							]"
							placeholder="Selecione um Banco"
							class="select"
							show-search
							option-filter-prop="children"
							:filter-option="filterOption"
						>
							<a-select-option
								v-for="bank in banks"
								:key="bank.code"
								:value="bank.code"
							>
								{{ bank.label }}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
				<a-col :span="6">
					<a-form-item label="Agência" has-feedback>
						<a-input
							v-decorator="[
								'agency',
								{
									initialValue: bank_account.agency,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe o número da sua agência!',
										},
										{
											pattern: /^\d{4}(\-\d{1})?$/,
											message:
												'Por favor, informe um número válido',
										},
									],
								},
							]"
							v-mask="['####', '####-#']"
							placeholder="Informe o número da agência"
						/>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="14">
					<a-form-item label="Tipo de conta" has-feedback>
						<a-select
							v-decorator="[
								'account_type',
								{
									initialValue: bank_account.is_savings
										? '1'
										: '0',
									rules: [
										{
											required: true,
											message:
												'Por favor, selecione o seu banco!',
										},
									],
								},
							]"
							placeholder="Selecione um tipo de conta"
							class="select"
						>
							<a-select-option value="0">
								Conta Corrente
							</a-select-option>
							<a-select-option value="1">
								Conta Poupança
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
				<a-col :span="6">
					<a-form-item label="Conta" has-feedback>
						<a-input
							v-decorator="[
								'account',
								{
									initialValue: bank_account.account,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe o número da conta!',
										},
										{
											pattern: /^\d{6}\-\d{1}$/,
											message:
												'Por favor, informe um número válido',
										},
									],
								},
							]"
							v-mask="'######-#'"
							placeholder="000000-0"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="4">
					<a-form-item>
						<div style="padding-top: 38px;">
							Conta PJ:
							<a-checkbox
								v-model="isJuridic"
								style="padding-left: 8px;"
								@change="handleChange"
							/>
						</div>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row :gutter="8" style="padding-left: 5px;"> </a-row>
		</a-form>
		<a-row type="flex" justify="center" class="buttons">
			<a-col>
				<a-button
					type="primary"
					style="width: 300px;"
					@click="onForward"
				>
					Avançar
					<a-icon type="right" />
				</a-button>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { stateList } from '@/assets/emitterStateList'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { axios } from '@/utils/axios'
import notification from 'ant-design-vue/es/notification'
import {
	validateCNPJ,
	mapCompanyKeys,
	mapAddressKeys1,
	mapAddressKeys2,
} from '@/utils/utils'

export default {
	name: 'CompanyInfo',
	directives: { mask },
	data() {
		return {
			stateList,
			searchStatus: '',
			searchHelp: '',
			hasFeedback: false,
			searchStatusZip: '',
			searchHelpZip: '',
			hasFeedbackZip: false,
			banks: [],
			enableSeachZip: false,
			desabledFieldsCompany: false,
			desabledFieldsAddress: false,
			isJuridic: false,
			validatedCNPJ: true,
			formCompany: this.$form.createForm(this, { name: 'company' }),
			formAddress: this.$form.createForm(this, { name: 'address' }),
			formBank: this.$form.createForm(this, { name: 'bank' }),
		}
	},
	computed: {
		...mapGetters({
			company: 'registerForms/company',
			address: 'registerForms/address',
			bank_account: 'registerForms/bank_account',
		}),
	},
	async beforeCreate() {
		try {
			const res = await axios.get('/v1/banks/')
			this.banks = res[0]
		} catch (err) {
			notification.error({
				message: 'Falha de conexão',
				description: 'Erro ao baixar algumas informações do servidor',
				duration: 6,
			})
		}
	},
	beforeMount() {
		this.isJuridic = this.bank_account.is_juridic

		if (this.company.cnpj !== '') this.desabledFieldsCompany = true

		if (this.address.postal_code !== '') this.desabledFieldsAddress = true
	},
	methods: {
		...mapActions('registerForms', ['addCompany']),
		async onValidateCNPJ(_, value, callback) {
			if (value && value.length == 18 && !this.validatedCNPJ) {
				if (validateCNPJ(value)) {
					try {
						await this.fetchCompany(value.replace(/\D+/g, ''))
					} finally {
						this.validatedCNPJ = true
						this.hasFeedback = true
						this.searchStatus = 'success'
						this.searchHelp = ''

						callback()
					}
				} else {
					this.validatedCNPJ = false
					this.hasFeedback = true
					this.searchStatus = 'error'
					this.searchHelp = 'Por favor, informe um CNPJ válido!'

					callback('Por favor, informe um CNPJ válido!')
				}
			} else {
				this.validatedCNPJ = false
				callback()
			}
		},
		onForward() {
			this.formCompany.validateFieldsAndScroll((err1, company) => {
				if (!err1) {
					this.formAddress.validateFieldsAndScroll(
						(err2, address) => {
							if (!err2) {
								this.formBank.validateFieldsAndScroll(
									async (err3, bank) => {
										if (!err3) {
											bank.is_juridic = this.isJuridic
											bank.is_savings =
												bank.account_type === '1'

											await this.addCompany({
												company,
												address,
												bank_account: bank,
											})

											this.$router.push(
												'/steps/merchant-info/'
											)
										}
									}
								)
							}
						}
					)
				}
			})
		},
		async fetchCompany(cnpj) {
			this.hasFeedback = true
			this.searchStatus = 'validating'
			this.searchHelp = 'Buscando informações do restaurante...'

			try {
				const res = await axios.get('/v1/search/?cnpj=' + cnpj)
				res.telefone = res.telefone.split('/')[0]

				for (let key in mapCompanyKeys) {
					let fieldValue = {}
					fieldValue[mapCompanyKeys[key]] = res[key]
					this.formCompany.setFieldsValue(fieldValue)
				}

				for (let key in mapAddressKeys1) {
					let fieldValue = {}
					fieldValue[mapAddressKeys1[key]] = res[key]
					this.formAddress.setFieldsValue(fieldValue)
				}

				this.desabledFieldsCompany = true
				this.desabledFieldsAddress = true
			} finally {
				this.hasFeedback = true
				this.searchStatus = 'success'
				this.searchHelp = ''
			}
		},
		handleChange(e) {
			this.isJuridic = e.target.checked
		},
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			)
		},
		onChangeZip(e) {
			const { value } = e.target
			if (value.length === 9) {
				if (this.enableSeachZip)
					this.fetchAddress(value.replace(/\D+/g, ''))
			} else {
				this.enableSeachZip = true
			}
		},
		async fetchAddress(zipcode) {
			this.hasFeedbackZip = true
			this.searchStatusZip = 'validating'
			this.searchHelpZip = 'Buscando informações do restaurante...'

			try {
				const res = await axios.get('/v1/search/?cep=' + zipcode)
				for (let key in mapAddressKeys2) {
					let fieldValue = {}
					fieldValue[mapAddressKeys2[key]] = res[key]
					this.formAddress.setFieldsValue(fieldValue)
					this.desabledFieldsAddress = true
				}
			} finally {
				this.hasFeedbackZip = true
				this.searchStatusZip = 'success'
				this.searchHelpZip = ''
			}
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	font-weight: bold;
	color: rgba(0, 0, 0, 0.65);

	.sign-wpp {
		margin: 15px auto;
		color: #3574b6;
		border-radius: 5px;
	}

	.select {
		font-weight: normal;
	}

	.buttons {
		margin: 40px auto 10px;
	}
}
</style>
